// Core imports
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../layouts/mainLayout';

import { Wrapper } from '../styles/pages/carpet-cleaning';

import HeroImage from '../components/heroImage';

export const query = graphql`
  query carpetCleaningPageQuery {
    hero: file(relativePath: { regex: "/mini-hero-carpet-half-cleaned.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tipsImage: file(relativePath: { regex: "/do-it-myself.png/" }) {
      childImageSharp {
        fluid(maxWidth: 675, maxHeight: 225) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    informationImage: file(
      relativePath: { regex: "/carpet-cleaning-information.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 675, maxHeight: 225) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyChooseUsImage: file(relativePath: { regex: "/why-choose-us.png/" }) {
      childImageSharp {
        fluid(maxWidth: 675, maxHeight: 225) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contactUsImage: file(relativePath: { regex: "/get-in-touch.png/" }) {
      childImageSharp {
        fluid(maxWidth: 675, maxHeight: 225) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const hero = data => {
  return (
    <HeroImage
      image={
        <Img
          style={{ minHeight: '300px' }}
          fluid={data.hero.childImageSharp.fluid}
        />
      }
      backdrop={false}
    >
      <h1
        style={{ textTransform: 'none', textAlign: 'center', fontSize: '60px' }}
      >
        Carpet Cleaning
      </h1>
    </HeroImage>
  );
};

const CarpetCleaningPage = ({ data }) => {
  return (
    <Layout hero={hero(data)}>
      <Helmet>
        <meta
          name="description"
          content="Premium carpet cleaning servicing Blacksburg, Roanoake, Salem, Christiansburg, and surrounding areas."
        />
        <title>Carpet Cleaning | Sci-Tech Carpet Cleaning</title>
      </Helmet>
      <Wrapper>
        <div
          className="page compressed"
          style={{
            paddingTop: '0',
          }}
        >
          <section className="introduction-section">
            <p>
              <strong>Thank you</strong> for visiting our carpet cleaning
              resources section.
            </p>
            <p>
              We have put together a few useful resources for you. Click
              whichever section link below best represents how we can help you
              today.
            </p>
            <p>
              If none of the links satisfy your needs, please{' '}
              <Link to="/contact">contact us</Link> and we will get you the
              answers you need!
            </p>
          </section>
        </div>
        <div
          className="page narrow"
          style={{
            paddingTop: '0',
          }}
        >
          <section className="buttons-section">
            <div className="button-wrapper .test">
              <Link to="/diy-carpet-cleaning-tips">
                <Img fluid={data.tipsImage.childImageSharp.fluid} />
              </Link>
            </div>
            <div className="button-wrapper">
              <Link to="/carpet-cleaning-information">
                <Img fluid={data.informationImage.childImageSharp.fluid} />
              </Link>
            </div>
            <div className="button-wrapper">
              <Link to="/why-choose-us">
                <Img fluid={data.whyChooseUsImage.childImageSharp.fluid} />
              </Link>
            </div>
            <div className="button-wrapper">
              <Link to="/contact">
                <Img fluid={data.contactUsImage.childImageSharp.fluid} />
              </Link>
            </div>
          </section>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default CarpetCleaningPage;
